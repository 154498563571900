<template>
  <div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="事件代码" prop="thirdeventcode">
              <a-input v-model.trim="queryParams.thirdeventcode" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="事件名称" prop="thirdeventname">
              <a-input v-model.trim="queryParams.thirdeventname" placeholder="请输入"></a-input>
            </a-form-model-item>
            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="事件类型" prop="eventtype">
              <a-select v-model="queryParams.eventtype">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option v-for="(item, index) in eventOptions" :key="index" :value="item.key">{{item.value}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="关联标准事件代码" prop="eventcode">
              <a-input v-model.trim="queryParams.eventcode" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="平台类型" prop="platformtype">
              <a-select v-model="queryParams.platformtype">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option value="1">汇川平台</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">事件列表</h3>
          <div class="table-btns">
            <a-button type="primary" @click="showModal('add')"><a-icon type="plus"></a-icon>新建</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="thirdeventid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="eventtype" slot-scope="value">
            {{eventTips[value]||''}}
          </span>
          <span slot="operation" slot-scope="value, record, index">
            <a-button type="link" size="small" @click="showModal('detail', value, record, index)">详情</a-button>
            <a-button type="link" size="small" @click="showModal('edit', value, record, index)">修改</a-button>
            <a-button type="link" size="small" @click="deleteConfirm(value, record, index)">删除</a-button>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData" @get-operation-result="getTableData"></add-or-edit-modal>
  </div>
</template>

<script>
import { getItemFromArrayByKey } from 'U'
import store from '@/store'
import { mapState } from 'vuex'
import pagination from '@/mixins/pagination'
import { getThirdeventListByCondition, deleteThirdevent } from 'A/ai.js'
import addOrEditModal from './AddOrEditModal'
import {eventOptions,eventTips} from '@/json/wlwhistory'

export default {
  name: 'elevatorProperties',
  mixins: [pagination],
  components: {
    addOrEditModal
  },
  data() {
    return {
      showAdvanced: false,
      eventOptions,
      eventTips,
      queryParams: {
        thirdeventcode: '',
        thirdeventname: '',
        eventtype: '',
        eventcode: '',
        platformtype: '',
      },
      tableColumns: [
        {
          title: '事件代码',
          dataIndex: 'thirdeventcode',
          key: 'thirdeventcode',
          ellipsis: true,
        },
        {
          title: '事件名称',
          dataIndex: 'thirdeventname',
          key: 'thirdeventname',
          ellipsis: true,
        },
        {
          title: '事件类型',
          dataIndex: 'eventtype',
          key: 'eventtype',
          ellipsis: true,
          scopedSlots: { customRender: 'eventtype' }
        },
        {
          title: '关联的标准事件代码',
          dataIndex: 'eventcode',
          key: 'eventcode',
          ellipsis: true,
        },
        {
          title: '描述',
          dataIndex: 'thirdeventdesc',
          key: 'thirdeventdesc',
          ellipsis: true,
        },
        {
          title: '操作',
          key: 'operation',
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      counts:'',
    }
  },
  beforeRouteEnter(to, from, next) {
    let toTab = getItemFromArrayByKey(store.state.main.tabs, 'id', to.name);
    if(!toTab) {
      store.commit('delKeepAlive', to.name)
    }
    next()
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getTableData();
    },
    resetQueryParams() {
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getThirdeventListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    deleteConfirm(value, record) {
      this.$confirm({
        // title: '',
        content: '确定要删除该数据?',
        okText: '确定',
        cancelText: '取消',
        onOk:() => {
          this.delete(record.thirdeventid);
        },
        onCancel() {},
      });
    },
    delete(thirdeventid) {
      this.showLoading();
      if(thirdeventid) {
        let params = {
          thirdeventid
        };
        deleteThirdevent(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    showModal(type, value, record) {
      this.modalShowType = type;
      if(type == 'add') {
        this.modalDetailData = null;
      }else {
        this.modalDetailData = record;
      }
      this.modalVisible = true;
    },
  }
}
</script>

<style lang="scss" scoped>
</style>